@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');

html, body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

html #community {
    font-family: "poppins", "arial", sans-serif;
}

#root, #App, #LayoutWrapper, #RootLayout {
    min-height: 100vh;
}

#RootLayout {
    min-height: calc(100vh - 48px);
    background-color: #F6F9FB;
}

#LayoutWrapper {
    flex-direction: column;
    position: relative;
    width: 100%;
}

#root #App #LayoutWrapper .absolute + div {
    /* fix for very aggrevating extra 10px of space under the imported top bar. -JJ */
    margin-top: 48px;
}

#community #root #App .leftNavLink,
#community #root #App .leftNavWithChildren {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    padding: 0.25rem 0.5rem;
    color: #fff;
}

#community #root #App .leftNavChild {
    display: block;
    margin: 0 0 0 1.5rem;
    padding: 0.25rem 0.5rem;
    color: #fff;
}

#community #root #App .leftNavLink:hover,
#community #root #App .leftNavWithChildren:hover,
#community #root #App .leftNavChild:hover
 {
    border-radius: 0.25rem;
    background-color: rgba(256,256,256,0.2);
}

#community #root #App .leftNavLink.active .chakra-text {
    font-weight: bold;
}

.guidelines ul,
.guidelines ol,
.eula ul,
.eula ol {
    list-style-position: outside;
    margin-left: 2rem;
    font-weight: 400;
}

.guidelines li, .eula li {
    margin-bottom: 0.5rem;
}

/* React  Quill custom container*/

#community .quill {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    height: 15rem;
    font-weight: 500;
}

#community .ql-container {
    background-color: white;
    border-radius:0px 0px 4px 4px;
    border: 1px solid #CED8DD;
    border-top: none;
    height: calc(100% - 45px);
}

#community .ql-toolbar {
    background-color: white;
    border-bottom: 0px;
    border: 1px solid #CED8DD;
    border-radius: 4px 4px 0px 0;
}

.overRide-bulletPoint-style ul, .overRide-bulletPoint-style ol{
    margin-left: 2rem;
}


blockquote{
    border-left: 4px solid red;
    padding-left: 1rem;
    width:100%;
    display: block;
    background-color: rgb(210, 210, 210);
}
/* Fix for MUI components appearing behind Chakra Modals */
.MuiPickersPopper-root.MuiPopper-root {
    z-index: var(--chakra-zIndices-popover);
    }


/* Style for card */
.postCard {
    margin-top: 0;
    border-bottom: 1px solid #CED8DD;
    margin-bottom: 1rem;
  }
  
  .postCard:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  


  /* Override quill style */

  .forceFontStyleH4{
    display: flex;
  }

  .forceFontStyleH3 p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .forceFontStyleH4 p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  
  

.quote_char p{
    display: contents;
}

.notifDescription *{
    overflow-wrap: anywhere;
}

footer button {
    width: 100%;
}

footer.chakra-popover__footer button[aria-label="Log out"] {
    background: none !important;
    border: none;
    padding: 0 !important;
    line-height: 1.625 !important;
    font-style: normal;
    height: 1.25rem !important;
}


footer div button {
    background: none!important;
    border: none;
    padding: 0!important;
    line-height: 1.625 !important;
    font-style: normal;
    height: 1.25rem !important;
}

.chakra-modal__footer button[aria-label="Continue session"] {
    width: 12rem;
}

.chakra-modal__footer    button[aria-label="Log out"] {
    width: 10rem;
}
